



























































































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  ApplicationPaymentDto,
  AttachmentHostType,
  AuditFlowScope,
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "ApplicationPaymentDetail",
  components: {
    ExportWord,
    AuditBlock,
    AttachmentsView,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class ApplicationPaymentDetail extends Vue {

  dataId?: number;
  detail: ApplicationPaymentDto = {};
  styles = "";
  selector = "table-detail-world";
  filename = "用款申请详情";
  hostType = AttachmentHostType.ApplicationPayment;


  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.$route.params.id) {
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    api.applicationPayment
      .get({ id: Number(this.$route.params.id) })
      .then((res) => {
        this.detail = { ...res };
      });
  }


  get auditApi(){
    return {canAudit:api.applicationPayment.canAudit,userAudit:api.applicationPayment.audit} as AuditApi
  }


  // 返回
  private cancel() {
    this.$router.back();
  }
}
